import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";

function ImpressPage() {
  return (
    <Layout>
      <SEO
        title="Impressum"
        keywords={[`gatsby`, `tailwind`, `react`, `tailwindcss`]}
      />
<section className="text-center">
        <h1>Impressum</h1>
        Baustelle - Seite noch im Aufbau
        </section>
        

    </Layout>
  );
}

export default ImpressPage;
